import React from "react";
import styled from "styled-components";
import startSound from "./audio/boxing-bell.mp3";
import lastSecondsSound from "./audio/censor-beep-1.mp3";
import finalSound from "./audio/censor-beep-3.mp3";
import plateSound from "./audio/tarelka.mp3";
import logoImg from "./white_logo_without_triangle.png";

const Container = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 10px;
`;

const Clock = styled.div`
  font-size: 400px;
  line-height: 0.9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
`;

const Controls = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  width: calc(60px);
  height: calc(60px);
  font-size: 12px;
  font-weight: bold;
  background-image: none;
  color: #fff;
  background: #252626;
  outline: none;
  border-radius: 50%;
  border: none;
`;

const Logo = styled.img`
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 375px;
`;

const Settings = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 300px;
  transform: translateX(-50%);
  border: 1px solid #fff;
  background: #000;
  padding: 15px;
  display: flex;
`;

const MILISECONDS_IN_MIN = 60 * 1000;

const DEFAULT_SETTINGS = {
  time: 4 * MILISECONDS_IN_MIN,
  repeating: false,
  //delay secs
  delay: 0
}

const TIME_OPTIONS = Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (x) => {
  return {
    name: x,
    value: x * MILISECONDS_IN_MIN
  }
})


const startAudio = new Audio(startSound);
const lastSecondsAudio = new Audio(lastSecondsSound);
//const finalAudio = new Audio(finalSound);

const plateAudio = new Audio(plateSound);
function App() {
  const [state, setState] = React.useState({
    status: null,
    time: DEFAULT_SETTINGS.time,
    isSettingsOpened: false,
    settings: DEFAULT_SETTINGS,
    isDelayGoes: false
  });

  const interval = React.useRef(null);
  const decTime = () => {
    setState(({ time, ...state }) => ({ ...state, time: time - 10 }));
  };
  const start = () => {
    if (!state.status) {
      startAudio.play();
    }
    setState({ ...state, status: "play", isSettingsOpened: false, });
    interval.current = setInterval(decTime, 10);
  };
  const pause = () => {
    setState({ ...state, status: "paused" });
    clearInterval(interval.current);
  };

  const reset = () => setState({ ...state, time: state.settings.time, status: null, isDelayGoes: false });

  const time = React.useMemo(() => {
    const totalSeconds = Math.floor(state.time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${(minutes > 9 ? "" : "0") + minutes}:${(seconds > 9 ? "" : "0") + seconds
      }`;
  }, [state.time]);

  React.useEffect(
    function () {
      if (time === "01:00" && Number(state.settings.time) !== MILISECONDS_IN_MIN) {
        plateAudio.play();
      } else if (
        ["00:10", "00:05", "00:04", "00:03", "00:02", "00:01"].indexOf(time) >
        -1
      ) {
        lastSecondsAudio.play();
      } else if (time === "00:00") {
        startAudio.play();
        if (!state.settings.repeating) {
          clearInterval(interval.current);
          setState({ ...state, time: 0, status: "done" });
        } else {

          clearInterval(interval.current);
          setState({ ...state, time: 0 });
          setTimeout(() => {
            const timeToStart = state.isDelayGoes || Number(state.settings.delay) == 0 ? state.settings.time : ((Number(state.settings.delay) + 1) / 60) * MILISECONDS_IN_MIN
            setState({ ...state, time: timeToStart, isDelayGoes: !state.isDelayGoes });
            //setState((prevState) => ({ ...prevState, time: timeToStart }))
            interval.current = setInterval(decTime, 10);
          }, 1000)
        }
      }
    },
    [time]
  );

  function handleChangeTime({ target }) {
    setState({
      ...state,
      ...(!state.status && {
        time: target.value
      }),
      settings: {
        ...state.settings,
        time: target.value
      }
    })
  }

  function handleChangeRepeating({ target }) {
    setState({
      ...state,
      settings: {
        ...state.settings,
        repeating: target.value === 'yes',
        //reset delay value
        delay: 0
      }
    })
  }

  function handleChangeDelay({ target }) {
    setState({
      ...state,
      settings: {
        ...state.settings,
        delay: target.value
      }
    })
  }

  function openSettings() {
    setState((prevState) => { return { ...prevState, isSettingsOpened: true } })
  }
  function closeSettings() {
    setState((prevState) => { return { ...prevState, isSettingsOpened: false } })
  }

  return (
    <Container>
      <Logo src={logoImg} />
      <Clock>{time}</Clock>
      <Controls>
        {state.status === null && <Button onClick={start}>Start</Button>}

        {state.status === "play" && (
          <>
            <Button onClick={pause}>Pause</Button>
          </>
        )}
        {state.status === "paused" && (
          <>
            <Button onClick={start}>Continue</Button>
            <Button onClick={reset}>Stop</Button>
          </>
        )}
        {state.status === "done" && <Button onClick={reset}>Reset</Button>}
        {!state.status && (
          <Button onClick={openSettings}>Settings</Button>
        )}
        {state.isSettingsOpened && (
          <Settings>
            <button onClick={closeSettings} className="close">Х</button>
            <div className="form-control">
              <p className="label">Minutes</p>
              <select className="field" value={state.settings.time} onChange={handleChangeTime}>
                {TIME_OPTIONS.map(({ name, value }) => (<option key={name} value={value}>{name}</option>))}
              </select>
            </div>
            <div className="form-control">
              <p className="label">Infinity</p>
              <div className="radio">
                <div className="radio__option">
                  <input type="radio" name="repeating" id="repeating-no" checked={!state.settings.repeating}
                    onChange={handleChangeRepeating} value="no" />
                  <label htmlFor="repeating-no">No</label>
                </div>
                <div className="radio__option">
                  <input type="radio" name="repeating" id="repeating-yes" checked={state.settings.repeating}
                    onChange={handleChangeRepeating} value="yes" />
                  <label htmlFor="repeating-yes">Yes</label>
                </div>
              </div>
              {state.settings.repeating && (
                <div >
                  <p className="label">Delay (sec)</p>
                  <input className="field" type="number" value={state.settings.delay} onChange={handleChangeDelay} />
                </div>
              )}
            </div>
          </Settings>
        )}
      </Controls>
    </Container>
  );
}

export default App;
